
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '@/lib/util'
    import AbpBase from '@/lib/abpbase'
    import PageRequest from '@/store/entities/page-request'
    import CreateCompany from './create-company.vue'
    import CompanyDto from "@/store/entities/companycontext/company";
    import SortingModel from "@/store/entities/sortingmodel";

    class PageCompanyRequest extends PageRequest {
        keyword: string;
        categoryId: number | null;
        status: boolean | null;
    }

    @Component({
        components: {CreateCompany}
    })
    export default class Company extends AbpBase {
        categories: any;

        data() {
            return {
                categories: [],
                updateCount: 0,
            }
        };

        async mounted() {
            this.categories = await this.$store.dispatch('category/lookUp');
        }

        async changeSort(data) {
            if (this.pageRequest.sorting == null) {
                this.pageRequest.sorting = [];
            }

            let item = this.pageRequest.sorting.find(x => x.fieldName === data.key)
            if (item == null) {
                let sortingModel = new SortingModel();
                sortingModel.fieldName = data.key;
                sortingModel.isDescendingDirection = data.order === "desc";
                this.pageRequest.sorting.push(sortingModel)
            } else {
                item.isDescendingDirection = data.order === "desc";
            }
            await this.getpage();
        }

        async handleUpload(file) {
            let formData = new FormData();
            formData.append('file', file);
            await this.$store.dispatch({
                type: 'company/uploadFile',
                data: formData
            })
            await this.getpage();
        }

        isCategoryChange(val: number) {
            if (val === 0)
                this.pageRequest.categoryId = null;
            else
                this.pageRequest.categoryId = val;
        }

        iIsVerifiedChange(val: string) {
            if (val === 'Yes') {
                this.pageRequest.status = true;
            } else if (val === 'No') {
                this.pageRequest.status = false;
            } else {
                this.pageRequest.status = null;
            }
        }

        async edit(id: number) {
            await this.$router.push({name: 'edit-company', params: {companyId: id.toString()}});
            await this.getpage();
        }

        pageRequest: PageCompanyRequest = new PageCompanyRequest();

        createModalShow: boolean = false;
        editModalShow: boolean = false;

        get list() {
            return this.$store.state.company.list;
        };

        get loading() {
            return this.$store.state.company.loading;
        }

        async create() {
            this.createModalShow = true;
            this.$store.commit('company/edit', new CompanyDto());
        }

        pageChange(page: number) {
            this.$store.commit('company/setCurrentPage', page);
            this.getpage();
        }

        pagesizeChange(pagesize: number) {
            this.$store.commit('company/setPageSize', pagesize);
            this.getpage();
        }


        find() {
            this.pageChange(1);
        }

        async getpage() {

            this.pageRequest.maxResultCount = this.pageSize;
            this.pageRequest.skipCount = (this.currentPage - 1) * this.pageSize;

            await this.$store.dispatch({
                type: 'company/getAll',
                data: this.pageRequest
            })
        }

        get pageSize() {
            return this.$store.state.company.pageSize;
        }

        get totalCount() {
            return this.$store.state.company.totalCount;
        }

        get currentPage() {
            return this.$store.state.company.currentPage;
        }

        columns = [{
            title: this.L('Name'),
            key: 'name',
            sortable: 'custom'
        }, {
            title: this.L('AttachedTo'),
            key: 'attachedTo'
        }, {
            title: this.L('Status'),
            key: 'status'
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 150,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px',
                            visibility: this.hasEditPermission() ? 'visible' : 'hidden'
                        },
                        on: {
                            click: async () => {
                                await this.edit(params.row.id);
                            }
                        }
                    }, this.L('Edit')),
                    h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        },
                        style: {
                            visibility: this.hasDeletePermission() ? 'visible' : 'hidden'
                        },
                        on: {
                            click: async () => {
                                this.$Modal.confirm({
                                    title: this.L('Tips'),
                                    content: this.L('Delete Company Confirm'),
                                    okText: this.L('Yes'),
                                    cancelText: this.L('No'),
                                    onOk: async () => {
                                        await this.$store.dispatch({
                                            type: 'company/delete',
                                            data: params.row
                                        })
                                        await this.getpage();
                                    }
                                })
                            }
                        }
                    }, this.L('Delete'))
                ])
            }
        }]

        async created() {
            await this.getpage();
        }

        hasAddPermission(){
            return this.hasPermission("Pages.Companies.Add");
        }

        hasEditPermission(){
            return this.hasPermission("Pages.Companies.Edit");
        }

        hasDeletePermission(){
            return this.hasPermission("Pages.Companies.Delete");
        }
    }
